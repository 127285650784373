<template>
  <div v-loading="loading" class="member-shop-point-link-edit">
    <PageTitle
      :title="productId ? '編輯點數連結': '新增點數連結'"
      icon="chevron_left"
      hideBtn
      @iconClick="onCancel"
    />
    <div
      v-if="!loaded"
      v-loading="!loaded"
      element-loading-background="rgba(0, 0, 0, 0)"
      class="w-full"
      style="height: 500px"
    />
    <template v-if="loaded">
      <MemberShopPointLinkEditInfoBlock class="card-container form-wrapper" :productData="productData" />
    </template>
    <PageFixedFooter :confirmLoading="loading" @cancel="onCancel" @confirm="onSubmit" />
  </div>
</template>

<script>
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import { defineComponent, computed, ref, onMounted, reactive } from 'vue'
import MemberShopPointLinkEditInfoBlock from './components/MemberShopPointLinkEditInfoBlock.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import { FindShopPointLink, CreateShopPointLink, UpdateShopPointLink } from '@/api/shopPoint'
import { useRoute, useRouter } from 'vue-router/composables'
import formsContext from './context'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberShopPointLinkEdit',
  components: {
    PageTitle,
    PageFixedFooter,
    MemberShopPointLinkEditInfoBlock,
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const productId = computed(() => route.params.id)
    const productData = ref({})
    const loading = ref(false)
    const loaded = ref(false)

    const findShopPointLink = async () => {
      const [res, err] = await FindShopPointLink({
        shopId: shopId.value,
        id: productId.value,
      })
      if (err) return window.$message.error(err)
      productData.value = res
    }

    const createShopPointLink = async (payload) => {
      const [, err] = await CreateShopPointLink(payload)
      if (err) throw err
      window.$message.success('新增成功！')
    }
    const updateShopPointLink = async (payload) => {
      const [, err] = await UpdateShopPointLink(payload)
      if (err) throw err
      window.$message.success('更新成功！')
    }

    const onSubmit = async () => {
      loading.value = true
      const allPass = await formsContext.validateAll()
      if (!allPass) {
        loading.value = false
        return
      }
      const formData = formsContext.composFormData()
      const payload = {
        shopId: shopId.value,
        ...formData,
      }

      try {
        if (!productId.value) await createShopPointLink(payload)
        if (productId.value) {
          payload.id = productId.value
          await updateShopPointLink(payload)
        }
        router.push({ name: 'MemberShopPointLink' })
      } catch (error) {
        window.$message.error(error)
        loading.value = false
      }
    }

    const onCancel = () => {
      router.push({
        name: 'MemberShopPointLink',
      })
    }

    onMounted(async () => {
      if (productId.value) await findShopPointLink()
      loaded.value = true
    })

    return { onCancel, onSubmit, loading, productData, loaded, formsContext, productId }
  },
})
</script>

<style lang="postcss" scoped>
.member-shop-point-link-edit {
  @apply flex flex-col gap-[20px];
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
